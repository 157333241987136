var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("object", {
        attrs: {
          id: "PayGatePlugin",
          classid: "clsid:8C1E006B-FDF1-4AEF-9B8A-83D8004C2E8B",
          hidden: "hidden"
        }
      }),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            size: "lg",
            "no-close-on-backdrop": "",
            static: ""
          },
          on: { hide: _vm.hide },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("div", { attrs: { slot: "modal-header" }, slot: "modal-header" }, [
            _c("h3", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "alert alert-warning" }, [
                _c("strong", [_vm._v(_vm._s(_vm.text))])
              ])
            ])
          ]),
          _c("br"),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.browser !== "IE",
                  expression: "browser !== 'IE'"
                }
              ],
              staticClass: "row form-group"
            },
            [
              _c("div", { staticClass: "col-md-9" }, [
                _vm._v(_vm._s(_vm.webExtentionText))
              ]),
              _vm.webExtensionRequired
                ? _c("div", { staticClass: "col-md-3" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          type: "button",
                          disabled: _vm.webExtensionClicked
                        },
                        on: { click: _vm.getWebExtension }
                      },
                      [_vm._v("Get Extension")]
                    )
                  ])
                : _vm._e(),
              !_vm.webExtensionRequired && !_vm.installCancelled
                ? _c("div", { staticClass: "col-md-3" }, [
                    _c("span", {
                      staticClass: "fa fa-check-circle",
                      staticStyle: { "font-size": "200%", color: "green" }
                    })
                  ])
                : _vm._e()
            ]
          ),
          _c("br"),
          _c("div", { staticClass: "row form-group" }, [
            _c("div", {
              staticClass: "col-md-9",
              domProps: { innerHTML: _vm._s(_vm.loaderPluginText) }
            }),
            _vm.pluginRequired || _vm.pluginUpdateRequired
              ? _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        disabled: this.disableDownloadMsi
                      },
                      on: { click: _vm.downloadPayGatePluginMsi }
                    },
                    [_vm._v("Download Plugin")]
                  )
                ])
              : _vm._e(),
            !_vm.pluginRequired &&
            !_vm.pluginUpdateRequired &&
            !_vm.installCancelled
              ? _c("div", { staticClass: "col-md-3" }, [
                  _c("span", {
                    staticClass: "fa fa-check-circle",
                    staticStyle: { "font-size": "200%", color: "green" }
                  })
                ])
              : _vm._e()
          ]),
          _vm.getPluginClicked
            ? _c("div", { staticClass: "row form-group" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _c("div", { staticClass: "alert alert-warning" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.runInstallerText))])
                  ])
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer"
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-2" }, [
                  _vm.showContinue
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "button" },
                          on: { click: _vm.continueMethod }
                        },
                        [_vm._v("Continue")]
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "col-md-10" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger pull-right",
                      attrs: { type: "button" },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("Cancel")]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }