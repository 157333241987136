var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "form",
          {
            attrs: { novalidate: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _vm._m(0),
            _vm.isAuthenticator
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6 mx-auto" }, [
                    _vm.qrCode
                      ? _c("img", {
                          staticClass: "img-fluid",
                          staticStyle: {
                            "min-width": "150px",
                            "min-height": "150px"
                          },
                          attrs: { src: _vm.qrCode }
                        })
                      : _vm._e(),
                    _vm.qrCode
                      ? _c("span", { staticClass: "small" }, [
                          _vm._v(
                            "Please scan this QR code with an Authenticator App"
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: {
                      warn: _vm.$v.code.$error,
                      invalid: _vm.invalidCode
                    }
                  },
                  [
                    _c("div", [
                      _c("label", { staticClass: "label-control" }, [
                        _vm._v("Code")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.code,
                            expression: "code"
                          },
                          { name: "focus", rawName: "v-focus" }
                        ],
                        staticClass: "form-control col-md-6",
                        domProps: { value: _vm.code },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.code = $event.target.value
                            },
                            _vm.setErrorFlagFalse
                          ]
                        }
                      }),
                      _vm.$v.code.$dirty
                        ? _c("span", [
                            !_vm.$v.code.required
                              ? _c(
                                  "small",
                                  {
                                    staticClass: "form-text text-warning small"
                                  },
                                  [_vm._v("A code is required")]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]),
                    _vm.invalidCode
                      ? _c("span", [
                          _c(
                            "small",
                            { staticClass: "form-text text-danger small" },
                            [_vm._v("The code you have entered is incorrect")]
                          )
                        ])
                      : _vm._e(),
                    _c("small", { staticClass: "form-text text-muted" }, [
                      _vm._v(_vm._s(_vm.providerMessage))
                    ])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit" },
                    on: { click: _vm.submit }
                  },
                  [_vm._v("Submit")]
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("h2", { attrs: { id: "title-of-block-check" } }, [
          _vm._v("Setup 2FA")
        ]),
        _c("div", { staticClass: "alert alert-info small" }, [
          _vm._v(
            "Your administrator has forced users to use Two-Factor Authentication (2FA), as you have not been setup yet, please follow these steps to do so."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }