<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <form novalidate v-on:submit.prevent>
          <div class="row">
            <div class="col-sm-12">
              <h2 id="title-of-block-check">Setup 2FA</h2>
              <div
                class="alert alert-info small"
              >Your administrator has forced users to use Two-Factor Authentication (2FA), as you have not been setup yet, please follow these steps to do so.</div>
            </div>
          </div>
          <div class="row" v-if="isAuthenticator">
            <div class="col-md-6 mx-auto">
              <img
                v-if="qrCode"
                v-bind:src="qrCode"
                style="min-width:150px;min-height:150px"
                class="img-fluid"
              />
              <span v-if="qrCode" class="small">Please scan this QR code with an Authenticator App</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group" :class="{'warn': $v.code.$error,'invalid': invalidCode}">
                <div>
                  <!-- Label -->
                  <label class="label-control">Code</label>
                  <!-- Input -->
                  <input
                    v-model="code"
                    class="form-control col-md-6"
                    v-focus
                    @input="setErrorFlagFalse"
                  />

                  <!-- Validation Messages -->
                  <span v-if="$v.code.$dirty">
                    <small
                      class="form-text text-warning small"
                      v-if="!$v.code.required"
                    >A code is required</small>
                  </span>
                </div>
                <span v-if="invalidCode">
                  <small class="form-text text-danger small">The code you have entered is incorrect</small>
                </span>
                <!-- Help Text -->
                <small class="form-text text-muted">{{providerMessage}}</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button class="btn btn-primary" @click="submit" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'verifyCode',
  props: ['query'],

  computed: {
    isAuthenticator () {
      return this.query.selectedProvider === 'Authenticator'
    }
  },
  data () {
    return {
      code: null,
      providerMessage: null,
      authKey: '',
      qrCode: null,
      invalidCode: false
    }
  },
  methods: {
    async submit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        var response = await axios.post('Account/SetupCode', { Provider: this.query.selectedProvider, Code: this.code }, { showload: true })

        if (response.data.timedOut) {
          this.$router.replace({ name: 'Login', query: { returnUrl: this.query.returnUrl, message: 'Your request to login has timed out, please enter your login details again.' } })
          return
        }

        switch (response.data.response) {
          case 'Success':
            this.$snapbar.s('2FA has been settup, please log in again.')
            this.$router.replace({ name: 'Login', query: { returnUrl: this.query.returnUrl } })
            break
          case 'InvalidCode':
            this.invalidCode = true
            break
        }
      }
    },
    setErrorFlagFalse () {
      this.invalidCode = false
    }
  },
  async created () {
    var response = await axios.get('Account/SetupCode', { showload: true })

    if (response.data.timedOut) {
      this.$router.replace({ name: 'Login', query: { returnUrl: this.query.returnUrl, message: 'Your request to login has timed out, please enter your login details again.' } })
      return
    }

    this.providerMessage = response.data.providerMessage

    if (this.query.selectedProvider === 'Authenticator') {
      var codeResponse = await axios.get('Account/GetQrCode', { showload: true })
      this.qrCode = `data:image/bmp;base64,${codeResponse.data.image}`
      this.authKey = response.data.authKey
    }
  },
  validations () {
    return {
      code: { required }
    }
  }
}
</script>
