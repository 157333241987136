<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <form novalidate v-on:submit.prevent>
          <div class="row">
            <div class="col-sm-12">
              <h2 id="title-of-block-check">Verify Code</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <!-- Code -->
              <div class="form-group" :class="{'warn': $v.code.$error,'invalid': invalidCode}">
                <div v-if="!isUsbToken">
                  <!-- Label -->
                  <label class="label-control">Code</label>
                  <input
                    v-model="code"
                    focusInput
                    class="form-control col-md-6"
                    v-focus
                    @input="setErrorFlagFalse"
                  />

                  <!-- Validation Messages -->
                  <span v-if="$v.code.$dirty">
                    <small
                      class="form-text text-warning small"
                      v-if="!$v.code.required"
                    >A code is required</small>
                  </span>
                </div>
                <span v-if="invalidCode">
                  <small class="form-text text-danger small">The code you have entered is incorrect</small>
                </span>
                <!-- Help Text -->
                <small v-if="!isUsbToken" class="form-text text-muted">{{providerMessage}}</small>
                <small
                  v-if="isUsbToken"
                  class="form-text text-muted"
                >Clicking Submit will request the code from the usb token</small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button class="btn btn-primary" @click="submit" type="submit">Submit</button>
              <button
                v-if="!isAuthenticator && !isUsbToken"
                class="btn btn-light ml-3"
                @click="resend"
                :disabled="resendDisabled"
                type="button"
              >Resend</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <plugin></plugin>
  </div>
</template>
<script>

import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import Plugin from '@/Components/UsbTokens/Plugin'

export default {
  name: 'verifyCode',
  props: ['query'],
  components: {
    Plugin
  },
  computed: {
    isUsbToken () {
      return this.query.selectedProvider === 'USB Token'
    },
    isAuthenticator () {
      return this.query.selectedProvider === 'Authenticator'
    }
  },
  data () {
    return {
      code: null,
      providerMessage: null,
      invalidCode: false,
      resendDisabled: false
    }
  },
  methods: {
    async submit () {
      if (this.isUsbToken) {
        try {
          this.code = await this.$store.dispatch('getUsbTokenThumbprint')
        } catch (error) {
          this.$toastr.e(error.message, 'Error')
          return
        }
      }
      this.$v.$touch()

      if (!this.$v.$invalid) {
        var response = await axios.post('Account/VerifyCode', { Provider: this.query.selectedProvider, Code: this.code }, { showload: true })

        if (response.data.timedOut) {
          this.$router.replace({ name: 'Login', query: { returnUrl: this.query.returnUrl, message: 'Your request to login has timed out, please enter your login details again.' } })
          return
        }

        switch (response.data.response) {
          case 'Success':
            location.replace(process.env.VUE_APP_ROOT_API + this.query.returnUrl)
            break
          case 'InvalidCode':
            this.invalidCode = true
            break
          case 'LockedOut':
            this.$router.replace({ name: 'Login', query: { returnUrl: this.query.returnUrl, lockedOutMessage: 'Your account has been locked out, please try again later' } })
            break
          case 'ChangePassword':
            this.$router.replace({ name: 'ChangePassword', query: { code: response.data.code, returnUrl: this.query.returnUrl }, params: { userId: response.data.userId, passwordComplexity: response.data.passwordComplexity } })
            break
        }
      }
    },
    async resend () {
      this.resendDisabled = true

      var response = await axios.post('Account/ResendCode', { provider: this.query.selectedProvider }, { showload: true })

      if (response.data.timedOut) {
        this.$router.replace({ name: 'Login', query: { returnUrl: this.query.returnUrl, message: 'Your request to login has timed out, please enter your login details again.' } })
        return
      }

      this.$toastr.s('The code has been resent', 'Code Resend')

      setTimeout(() => {
        this.resendDisabled = false
      }, 10000)
    },
    setErrorFlagFalse () {
      this.invalidCode = false
    }
  },
  async created () {
    var response = await axios.get('Account/VerifyCode', { params: { provider: this.query.selectedProvider }, showload: true })

    if (response.data.timedOut) {
      this.$router.replace({ name: 'Login', query: { returnUrl: this.query.returnUrl, message: 'Your request to login has timed out, please enter your login details again.' } })
      return
    }

    this.providerMessage = response.data.providerMessage

    if (this.isUsbToken) {
      await this.$store.commit('setDeviceType', 'USB Token')
      await this.$store.dispatch('checkPlugin')
      this.submit()
    }
  },
  validations () {
    return {
      code: { required }
    }
  }
}
</script>
